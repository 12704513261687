.loading {
    display: flex; 
    justify-content: center; 
    width: 100%;
}

.loading .container {
    display: flex; 
    padding: 0.75rem; 
    background-color: transparent; 
    justify-content: center; 
    align-items: center; 
    width: 25%; 
    height: 9rem; 
    border-radius: 0.5rem; 
    box-shadow: 0 10px 15px -3px rgba(0, 0, 0, 0.1), 0 4px 6px -2px rgba(0, 0, 0, 0.05);
    border: 1px solid rgba(234, 234, 234, 0.41);
}

@keyframes spin {
    from {
      transform: rotate(0deg);
    }
    to {
      transform: rotate(360deg);
    }
  }

.loading svg {
    animation: spin 1s linear infinite;
    color: #1E40AF; 
    width: 4rem; 
    weight: 4rem; 
}

.loading circle {
    opacity: 0.25; 
}

.loading path {
    opacity: 0.75; 
}