.flags-container {
    padding: 0 4rem 3rem 4rem;
    font-family: 'Plus Jakarta Sans', sans-serif;
}

.flags-container h2 {
    font-size: 2rem;
    font-weight: 400;
    margin: 0;
    padding-bottom: 0.5rem;
    border-bottom: 1px solid rgb(209, 209, 209);
}

.flags-container p {
    font-size: 1.25rem;
}

.flags .wrapper {
    display: grid; 
    grid-template-columns: 1fr 1fr 1fr 1fr; 
    gap: 0px 0px;
    grid-auto-rows: 100px
}

.flags .container {
    padding: 0.5rem;
    display: flex;
    text-align: center;
    align-items: center;
    justify-content: center;
    border: 1px solid #9dacff;
    margin: 0.5rem;
    border-radius: 10px;
    transition: background-color 0.25s ease;
    font-size: 1.2rem;
}

.flags .emoji {
    font-size: 4rem;
}

.flags .container:hover {
    background-color: #c5dbfd76;
    color: #1f2f8a
}

.flags .searchbar {
    padding: 0.5rem 1rem;
    font-size: 1.5rem;
    border-radius: 10px;
    border: 1px solid grey;
}

.flags .searchbar:focus-visible {
    outline-color: inherit;
}

@media (max-width: 1280px) {
    .flags-container {
        padding: 0 2rem;
    }

    .flags .wrapper {
        grid-auto-rows: auto;
        grid-template-columns: 1fr 1fr 1fr
    }
}