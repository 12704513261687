html {
    font-size: 15px;
    scroll-behavior: smooth;
}

body {
    margin: 0;
    font-family: Arial, Helvetica, sans-serif;
    height: 100vh;
}

main {
    padding: 3rem 4rem;
}

#root {
    background-color: #f4f4f4;
    min-height: 100vh;
}

.container {
    padding: 10rem 10rem 3rem 13rem;
    display: flex;
    justify-content:flex-start;
    flex-wrap: wrap;
}

#codewarsBadge {
    padding-left: 1.5em;
}

#arrowContainer {
    padding: .5rem;
    width: fit-content;
    border-radius: 5px;
    box-shadow: 8px 8px 10px #3333333b;
    background-color: #0f4ba62e;
    position: fixed;
    right: 40px;
    transition: bottom .75s cubic-bezier(0.68, -0.55, 0.27, 1.55);
}

#arrowContainer:hover {
    background-color: #0f4ba698;
}

@media (max-width: 1280px) {
    html {
        font-size: 35px;
    }    

    .container {
       justify-content: center;
       padding: 10rem 2rem 5rem 2rem;
    }

    main {
        padding: 2rem
    }
}