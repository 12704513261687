#menuToggle {
	display: block;
	position: absolute;
	top: 50px;
	right: 50px;
	z-index: 1;
	-webkit-user-select: none;
	user-select: none;
}

#menuToggle input {
	display: block;
	width: 40px;
	height: 32px;
	position: absolute;
	top: -7px;
	left: -5px;
	cursor: pointer;
	opacity: 0;
	z-index: 2;
	-webkit-touch-callout: none;
}

#menuToggle span {
	display: block;
	width: 33px;
	height: 4px;
	margin-bottom: 5px;
	position: relative;
	background: #cdcdcd;
	border-radius: 3px;
	z-index: 1;
	transform-origin: 4px 0px;
	transition: transform 0.5s cubic-bezier(0.77, 0.2, 0.05, 1.0),
	background 0.5s cubic-bezier(0.77, 0.2, 0.05, 1.0),
	opacity 0.55s ease;
}

#menuToggle span:first-child {
	transform-origin: 0% 0%;
}

#menuToggle span:nth-last-child(2) {
	transform-origin: 0% 100%;
}

#menuToggle input:checked~span {
	opacity: 1;
	transform: rotate(45deg) translate(-2px, -1px);
	background: #232323;
}

#menuToggle input:checked~span:nth-last-child(3) {
	opacity: 0;
	transform: rotate(0deg) scale(0.2, 0.2);
}

#menuToggle input:checked~span:nth-last-child(2) {
	opacity: 1;
	transform: rotate(-45deg) translate(0, -1px);
}

#menu {
    display: flex;
    flex-direction: column;
	position: absolute;
	width: 300px;
	margin: -100px 0 0 0;
	padding: 50px;
	padding-top: 125px;
	right: -100px;
	background: #ededed;
	list-style-type: none;
	-webkit-font-smoothing: antialiased;
	transform-origin: 0% 0%;
	transform: translate(100%, 0);

	transition: transform 0.5s cubic-bezier(0.77, 0.2, 0.05, 1.0);
}

#menu li {
	padding: 10px 0;
	font-size: 22px;
}

#menuToggle input:checked~ul {
	transform: scale(1.0, 1.0);
	opacity: 1;
}