nav {
    padding: 1rem 0 1rem 0;
    display: flex;
    align-items: center;
    background-color: #ffffff;
    border: 1px solid #cacaca;
    height: 2.5rem;
    position: fixed;
    top: 0;
    width: 100%;
    z-index: 10000;
}

nav ul {
    padding: 0;
}

nav a {
    font-size: 1rem;
    padding: 0.5rem 1.75rem 0.5rem 1.75rem;
    font-family: 'Plus Jakarta Sans', sans-serif;
    border-right: 1px solid #cacaca;
    transition: 0.3s ease;
    color: #17161696;
    letter-spacing: 0.5mm;
    border-bottom: 0;
    text-decoration: none;

}

nav a:hover {
    color: #0f4ba6;
}

nav .navNombre {
    font-weight: 800;
    letter-spacing: 1mm;
    color: #3d3b3b;
    border-bottom: 0;
}
